import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout-minhelt/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import Activities from "../components/activities";
import Image from "../components/Image";
import TwoColsContainer from "../components/two-cols-container";
import { TextBoxH2 } from "../components/text-box";
import { HeroMinheletHome } from "../components/hero";
import YouTube from "react-youtube";
import useViewportWidth from "../hooks/useViewportWidth";
import hLine from "../images/line.svg";
import { string } from "../util/strings";

const IndexPage = ({ data, ...props }) => {

  const width = useViewportWidth();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowModal(true), 500);
  }, []);

  const seo = getMetatags(
    data.nodeHomepageMain
      .metatag /* , nodeOverview.relationships.OGImage?.localFile?.publicURL */
  );

  const { title0, title1, video } = data.nodeHomepageMain;
  const { hero, logos, textBlocks, section2Items, section3Items } =
    data.nodeHomepageMain.relationships;

  return (
    <>
      {showModal ? <Modal closeModal={setShowModal} /> : null}
      <Layout>
        <Seo {...seo} />

        <HeroMinheletHome
          className="mt-[90px] lg:mt-[126px] h-[147px] lg:h-[500px]  "
          {...hero}
        />
        <Logos items={logos} />
        <Section1 blocks={textBlocks} />
        <Section2 title={title0} items={section2Items} />
        <Section3 data={video} width={width} />
        <Section4 title={title1} items={section3Items} />

        {
          /* width !== 0 && */ <React.Suspense fallback="loading..."></React.Suspense>
        }
      </Layout>
    </>
  );
};

export default IndexPage;

const Modal = ({ closeModal }) => {
  
  useEffect(() => {
    
    const handleKeyDown = (event) => {
      if (event.keyCode === 9) {
        event.preventDefault();
      }
    }
    
    const element = document.getElementById("modal")
    element.addEventListener('keydown', handleKeyDown);

    return () => {
      element.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <div
      className="fixed top-0 left-0 z-[100] w-screen h-screen bg-white/70 flex justify-center items-center"
      id="modal"
    >
      <div className="relative">
        <a
          className="absolute z-90 top-[0px] right-[9px] text-transparent text-5xl font-bold"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            closeModal(false);
          }}
        >
          &times;
        </a>
        <Link to="/הרובע-הפתוח/מחשבון-פלוס">
          <img
            id="modal-img"
            className="hidden md:block max-w-[800px] max-h-[600px] object-cover"
            src="/backgrounds/PopUp_320x768_(2).jpg"
            alt="חדש! מחשבון פלוס. לחישוב זכיות הבניה באזור התעשייה הישן המתחדש"
          />
          <img
            id="modal-img-mobile"
            className="block md:hidden max-w-[800px] max-h-[600px] object-cover"
            src="/backgrounds/PopUp_320x320.jpg"
            alt="חדש! מחשבון פלוס. לחישוב זכיות הבניה באזור התעשייה הישן המתחדש"
          />
        </Link>
      </div>
    </div>
  );
};

const Logos = ({ items, ...props }) => {
  return (
    <section className=" bg-[#022B44]">
      <div className="container  max-w-screen-lg	 flex flex-row justify-around items-center py-4 lg:py-8  space-x-2 space-x-reverse lg:space-x-8 lg:space-x-reverse ">
        {items.map((item, index, all) => (
          <React.Fragment key={index}>
            <div className="">
              <Link to={item.link}>
                <img
                  src={item.relationships.image.localFile.publicURL}
                  alt={item.image.alt}
                  className=""
                />
              </Link>
            </div>
            {index + 1 < all.length && (
              <div>
                <img src={hLine} alt="" className="hidden lg:block" />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
};

const Section1 = ({ blocks, ...props }) => {
  return (
    <section className="pt-[2rem]">
      {blocks.map((e, index) => (
        <div key={index} className="container p-0">
          <Section0Element block={e} index={index} />
        </div>
      ))}
    </section>
  );
};

const Section0Element = ({ block, index, ...props }) => {
  //console.log(block)
  const img = (
    <Image
      image={{
        ...block.relationships.images[0],
        altText: "",
      }} /*alt={block.images[0].alt}*/
    />
  );

  const box = (
    <TextBoxH2
      className="px-4 lg:px-20 py-4 lg:py-20 "
      htmlClassName="text-justify	"
      showTitleDecoration={false}
      titleClassName="font-bold"
      title={block.title}
      html={block.html.processed}
      link={block.link}
    />
  );

  return (
    <TwoColsContainer
      colsClassName={
        " justify-center items-center h-full  " +
        (index % 2 !== 0 ? "bg-light-blue flex-col-reverse " : " ")
      }
      col2ClassName="lg:w-1/2  h-full"
      col1={index % 2 === 0 ? box : img}
      col2={index % 2 !== 0 ? box : img}
    />
  );
};

const Section2 = ({ title, items, ...props }) => {
  //console.log(items)
  return (
    <section className="py-12">
      <div className="container space-y-12 text-center">
        <h2 className="text-blue text-[34px] font-bold">{title}</h2>
        <div className="flex flex-col lg:flex-row justify-center items-baseline space-y-8 space-x-10 lg:space-x-reverse">
          {items.map((e, index) => (
            <div key={index} className="w-full lg:w-auto ">
              <Link to={e.link} className="">
                <Image
                  image={{ ...e.relationships.image, altText: e.image.alt }}
                  className="w-full lg:h-[380px]"
                />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Section3 = ({ data, width }) => {
  const height = width < 768 ? "360px" : "800px";

  const opts = {
    width: "100%",
    height: height,

    //playerVars: {
    // https://developers.google.com/youtube/player_parameters
    // autoplay: 1,
    //},
  };

  return (
    <section className="bg-[#FAFAFA] py-12 lg:py-32">
      <div className="container w-full lg:w-[1200px] h-[480px] lg:h-[800px] text-center mx-auto">
        <h2 className="text-blue text-3xl lg:text-[34px] font-bold mb-8 lg:mb-16">
          {string("video_title")}
        </h2>
        <YouTube
          videoId={data.video_id}
          opts={opts} /* onReady={this._onReady} */
        />
      </div>
    </section>
  );
};

const Section4 = ({ title, items, ...props }) => {
  return (
    <section className="lg:py-32 py-12">
      <div className="container space-y-12">
        <h2 className="text-center text-blue text-[26px] lg:text-[34px] font-bold">
          {title}
        </h2>
        <Activities items={items} className="" />
      </div>
    </section>
  );
};

/* export const Head = () => <title>Home Page</title> */

export const query = graphql`
  query IndexQuery {
    nodeHomepageMain(id: { eq: "d22b4dc9-a700-5140-9dd6-0e3b9f9c8310" }) {
      id
      title
      title0: field_title
      title1: field_title1
      video: field_youtube_video {
        input
        video_id
      }
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...Hero
        }
        logos: field_paragraph_repeater {
          ...ImageLink
        }
        textBlocks: field_paragraph_repeater1 {
          ...TitleHtmlImage
        }
        section2Items: field_paragraph_repeater2 {
          ...ImageLink
        }
        section3Items: field_paragraph_repeater3 {
          ...TitleTextImages
        }
      }
    }

    backgrounds: allFile(
      filter: { sourceInstanceName: { eq: "backgrounds" } }
    ) {
      nodes {
        base
        publicURL
      }
    }
  }
`;
