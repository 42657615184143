import React/* , { useState } */ from "react"
//import Image from "./Image";
//import TestimonialCard from "../components/testimonial-card"
//import useTestimonialsBlock from "../hooks/useTestimonialsBlock"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


const Activities = ({ items, className }) => {

  return (
    <>

      <div className="hidden lg:block activities">
        <Swiper
          modules={[Navigation, Pagination, Keyboard, A11y]}
          className={className}
          spaceBetween={60}
          slidesPerView={5}
          centeredSlides={true}
          roundLengths={true}
          initialSlide={2}
          loop={true}
          loopAdditionalSlides={30}
          //navigation={isMobile ? false : true}
          navigation
          pagination={{ clickable: true }}
          keyboard={true}    
          wrapperTag={'ul'}     
        //onSlideChange={() => console.log('slide change')}
        //onSwiper={(swiper) => console.log(swiper)}
        //onActiveIndexChange={(swiper) => console.log(swiper.activeIndex)}
        >
          {items.map((item, i) => (
            <SwiperSlide className="my-16 items-center " tag="li" key={i} >
              {({ isActive }) => (
                <CardDesktop  {...item} isActive={isActive} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>


      {/* <div className="hidden lg:block">
        <div className="flex justify-center space-x-12 space-x-reverse">
          {items.map((item, i) => (
            <CardDesktop key={i} {...item} />
          ))}
        </div>
      </div> */}

      <div className=" lg:hidden px-0">
        <Swiper
          modules={[Pagination]}
          className={className}
          spaceBetween={0}
          slidesPerView={1}
          //initialSlide={2}
          //navigation={isMobile ? false : true}
          //navigation
          pagination={{ clickable: true }}
        >
          {items.map((item, i) => (
            <SwiperSlide className="mb-12" key={i}>
              <CardMobile  {...item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  )
}


const CardDesktop = ({ title, description, image, relationships, isActive, link, ...props }) => {


  const backgroundImage = isActive
    ? `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url('${relationships.images[0]?.localFile?.publicURL}')`
    : `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%), url('${relationships.images[0]?.localFile?.publicURL}')`
    ;

  //console.log(link)

  return (
    <div
      className=" card-desktop  py-8 px-4 lg:h-[380px]   lg:w-[282px] lg:text-white space-y-4 -hover:scale-110 transition-all	duration-1000"
      style={{ backgroundImage: backgroundImage }}
      tabIndex={isActive?0:-1}
    >
      <h3 className="text-center text-lg font-bold text-white">{link?.uri ? <a href={link.uri}>{title}</a> : title}</h3>
      <div className="relative  overflow-hidden " >
        <div
          className={" text-center text-base leading-tight transition-all duration-500 " + (isActive ? 'top-0 block' : 'bottom-0 hidden')}
          dangerouslySetInnerHTML={{ __html: description?.processed }} />
      </div>
      <a className={"font-bold hover:underline mt-4 text-center " + (isActive ? 'top-0 block' : 'bottom-0 hidden')} href={link?.uri}>{link?.title}
      <span className="hidden">{' על' } {title}</span>
      </a>
      
    </div >
  )
}

const CardMobile = ({ title, description, images, relationships, link, ...props }) => {

  const backgroundImage = `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.0)), url('${relationships.images[0]?.localFile?.publicURL}')`;

  return (
    <div
      className="space-y-4 h-[400px] p-8 text-white bg-cover"
      style={{ backgroundImage: backgroundImage }}
    >
      <h3 className="text-center text-[22px] font-bold shadow-xl">{title}</h3>
      <div className={" max-h-[300px] overflow-hidden text-right text-lg"} dangerouslySetInnerHTML={{ __html: description?.processed }} />
    </div >
  )

}

export default Activities
